.footer {
    overflow: hidden;
}

.footerContent {
    composes: footer from './index.vars.css';
    background: var(--footer-content-background);
    border-top: var(--footer-content-border-top);
    padding: 0 20px;
}

.footerSecondaryContainer {
    position: relative;
    z-index: 1;
    background-color: var(--footer-secondary-bg);
    margin: 0 -20px;
    padding: 0 20px;
}

.withoutIconBar {
    margin-top: var(--footer-margin-top-mobile);
}

.footerIconBar {
    display: flex;
    align-items: flex-end;
    padding-left: 50px;
    background: var(--col--light);
    margin-top: var(--footer-margin-top-mobile);
}

.footerIconBar img {
    position: relative;
    top: 3px;
}

.footerIconBar img:nth-child(1) {
    margin-right: 3.45px;
}

.footerIconBar img:nth-child(2) {
    margin-right: 13.7px;
}

.link {
    line-height: var(--footer-link-line-height);
    font-size: var(--footer-link-font-size);
    font-weight: var(--footer-link-font-weight);
    text-transform: var(--footer-link-text-transform);
}

@media (max-width: 480px) {
    .link {
        font-weight: var(--footer-link-font-weight-mobile-view);
    }
}

.linkBlocksContainer {
    background-color: var(--footer-link-blocks-container-bg);
    position: relative;
    z-index: 1;
    border-bottom: 1px solid var(--footer-link-blocks-container-border-mobile);
}

.backgroundImage {
    position: absolute;
    bottom: var(--footer-background-bmage-bottom-mobile);
    right: var(--footer-background-bmage-right-mobile);
    opacity: var(--footer-background-bmage-opacity);
    z-index: -1;
}

.linkBlock {
    color: var(--col--light);
    margin-top: var(--footer-link-block-margin-top-mobile);
    margin-bottom: 40px;
}

.linkBlockHeading {
    color: var(--footer-link-block-heading-color);
    margin: var(--footer-link-block-heading-margin);
    font-size: var(--footer-link-block-heading-font-size);
    line-height: var(--footer-link-block-heading-line-height);
}

.linkBlockHeadingMobile {
    display: block;
}

.linkBlockHeadingDesktop {
    display: none;
}

.linkBlockList {
    margin: 0;
    padding-right: 8px;
    background-color: transparent;
}

.linkBlockList li:not(:last-child) {
    margin-bottom: 5px;
}

.linkBlockList li {
    text-decoration: none;
    color: var(--footer-list-unordered-links-li-color);
}

.linkBlockList li::before {
    color: var(--footer-list-unordered-links-li-before-color);
}

.linkBlockList a span {
    text-decoration: underline;
    text-underline-offset: var(--footer-link-text-underline-offset);
}

.linkBlockList a .externalLinkIcon {
    position: var(--footer-list-unordered-links-a-position);
    top: var(--footer-list-unordered-links-a-top);
    text-decoration: none;
    margin-left: 6px;
}

.socialLinkBlockList {
    display: flex;
    list-style: none;
    padding: var(--footer-social-link-block-list-padding);
}

.socialLinkBlockList span {
    margin-right: 15px;
}

.socialLinkBlockList span a {
    background-color: var(--footer-social-link-block-list-span-a);
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondaryBlockLinksContainer {
    padding: var(--footer-secondary-block-links-container-padding-mobile);
    color: var(--footer-secondary-color);
    border-bottom: 1px solid var(--footer-secondary-block-links-container-border-mobile);
}

.secondaryBlockLinksList {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    column-gap: 20px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.secondaryBlockLinksList li {
    font-family: var(--ff--link);
    padding: 0;
}

.secondaryBlockLinksList li::before {
    content: none;
}

.secondaryBlockLinksList li a {
    display: inline-block;
    font-family: var(--footer-secondary-block-links-list-li-a-font-family);
    line-height: var(--footer-secondary-block-links-list-li-a-line-height);
    margin-bottom: var(--footer-secondary-block-links-list-li-a-margin-bottom);
    font-weight: var(--footer-secondary-block-links-list-li-a-font-weight);
    vertical-align: top;
    font-size: 12px;
    text-transform: capitalize;
}

.secondaryBlockHintContainer {
    color: var(--footer-secondary-color);
    padding: var(--footer-secondary-block-hint-container-padding-mobile);
}

@media (min-width: 960px) {
    .withoutIconBar {
        margin-top: var(--footer-margin-top);
    }

    .footerIconBar {
        margin-top: var(--footer-margin-top);
    }

    .footerContent {
        padding: 0;
    }

    .footerSecondaryContainer {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
    }

    .backgroundImage {
        bottom: var(--footer-background-bmage-bottom-desktop);
        right: var(--footer-background-bmage-right-desktop);
    }

    .secondaryBlockLinksContainer {
        order: 2;
        border: none;
    }

    .linkBlocksContainer,
    .secondaryBlockLinksContainer {
        padding: var(--footer-links-block-padding);
    }

    .secondaryBlockHintContainer {
        flex-grow: 1;
        order: 1;
        padding: var(--footer-secondary-block-hint-container-padding);
        white-space: nowrap;
    }

    .linkBlocksContainer {
        overflow: hidden;
        flex-basis: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .linkBlock {
        flex-basis: 338px;
        padding-left: 30px;
        margin-bottom: var(--footer-link-block-margin-bottom);
        margin-top: 31px;
    }

    .linkBlock:not(:first-child) {
        border-left: var(--footer-link-block-border);
    }

    .secondaryBlockLinksList {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        columns: 1;
        column-gap: 0;
        margin: 0;
        padding: var(--footer-secondary-block-links-list-padding);
    }

    .secondaryBlockLinksList li::before {
        color: var(--col--gray5);
        content: var(--footer-secondary-block-links-list-before-content);
        width: 19px;
    }

    .secondaryBlockLinksList li {
        font-family: var(--ff--link);
        font-size: 14px;
        line-height: 18px;
        margin-right: 14px;
        padding-left: var(--footer-secondary-block-links-list-padding-left);
        white-space: nowrap;
    }

    .secondaryBlockLinksList li:first-child {
        padding-left: 0;
    }

    .secondaryBlockLinksList li:first-child::before {
        content: none;
    }

    .secondaryBlockLinksList li a {
        display: inline;
    }

    .secondaryBlockLinksList .secondaryBlockLinksBullet {
        display: block;
    }

    .linkBlockHeadingMobile {
        display: none;
    }

    .linkBlockHeadingDesktop {
        display: block;
    }
}
