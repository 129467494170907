.footer {
    --footer-content-background: var(--col--dark);
    --footer-content-border-top: 5px solid var(--col--primary1);
    --footer-links-block-padding: 0 21px;
    --footer-link-block-margin-bottom: 21px;
    --footer-link-line-height: 11px;
    --footer-link-font-size: 13px;
    --footer-link-text-transform: uppercase;
    --footer-link-block-heading-margin: 0 0 15px 0;
    --footer-link-block-heading-font-size: 16px;
    --footer-link-block-heading-line-height: 18px;
    --footer-link-block-heading-color: var(--col--light);
    --footer-link-block-margin-top-mobile: 41px;
    --footer-secondary-block-hint-container-padding: 14px 0 14px 51px;
    --footer-secondary-block-hint-container-padding-mobile: 12px 0;
    --footer-secondary-block-links-list-padding: 16px 0 13px 0;
    --footer-secondary-block-links-list-before-content: '\2022';
    --footer-secondary-block-links-list-padding-left: 19px;
    --footer-secondary-block-links-list-li-a-line-height: 18px;
    --footer-secondary-block-links-list-li-a-margin-bottom: 20px;
    --footer-secondary-block-links-list-li-a-font-family: var(--ff--link);
    --footer-secondary-block-links-list-li-a-font-weight: 400;
    --footer-social-link-block-list-padding: 6px 0 0 0;
    --footer-social-link-block-list-span-a: var(--col--primary1);
    --footer-secondary-bg: var(--col--dark);
    --footer-secondary-color: var(--col--gray5);
    --footer-background-bmage-opacity: 0.05;
    --footer-background-bmage-bottom-mobile: 17px;
    --footer-background-bmage-right-mobile: -20px;
    --footer-background-bmage-bottom-desktop: 0;
    --footer-background-bmage-right-desktop: 0;
    --footer-link-block-border: 1px solid rgba(255, 255, 255, 0.15);
    --footer-secondary-block-links-container-border-mobile: rgba(255, 255, 255, 0.15);
    --footer-secondary-block-links-container-padding-mobile: 35px 0 15px 0;
    --footer-margin-top: 80px;
    --footer-margin-top-mobile: 50px;
    --footer-link-font-weight: 800;
    --footer-link-font-weight-mobile-view: 700;
    --footer-link-blocks-container-bg: var(--col--dark);
    --footer-link-blocks-container-border-mobile: rgba(255, 255, 255, 0.15);
    --footer-list-unordered-links-li-color: var(--col--light);
    --footer-list-unordered-links-li-before-color: var(--col--primary1);
    --footer-list-unordered-links-a-position: static;
    --footer-list-unordered-links-a-top: 7px;
    --footer-link-text-underline-offset: auto;
}
